/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://kmwqo7oi7jbd5n2nax7we2nmja.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_McDq9Gasz",
    "aws_user_pools_web_client_id": "3i2jnqm4tmgts8qq4aa6jkhgek",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "AuditTrail-AuthenticatedOperations-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "AuditTrail-EventNames-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAnalyticsCategories-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAnalyticsEventLog-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAnalyticsExercises-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAnalyticsPatients-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAnalyticsTreatments-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "BQAppEvents-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "MobileDeviceInfo-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "SharedData-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "SimMapping-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "TreatmentDefinitions-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "UniqueValues-staging",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "bq-content155539-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
